import { ScrollElementContext } from "@shared//contexts/scroll-element-context";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { CookieConsent } from "@shared/components/cookie-consent/cookie-consent";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
    const [scrollElement, setScrollElement] = useState<HTMLElement | Window | null>(null);

    useEffect(() => {
        setScrollElement(window);
    }, []);

    return (
        <ScrollElementContext.Provider value={[scrollElement, setScrollElement]}>
            <div className="relative flex min-h-screen flex-col">{children}</div>
            <CookieConsent />
        </ScrollElementContext.Provider>
    );
};
