import { UxfCookiesConsentType } from "@shared/lib/cookie-consent/cookie-consent.types";

export function createCookieConsentData(granted: boolean): UxfCookiesConsentType {
    return {
        ad_personalization: granted,
        ad_storage: granted,
        ad_user_data: granted,
        analytics_storage: granted,
        functionality: granted,
    };
}
