import { Dialog } from "@headlessui/react";
import { Icon } from "@ui/components/icon/icon";
import { TextLink } from "@ui/components/text-link/text-link";
import { FC, ReactNode, useRef } from "react";

interface Props {
    children: ReactNode;
    disableCloseButton?: boolean;
    fullWidth?: boolean;
    isOpen: boolean;
    onClose: () => void;
}

export const ModalContent: FC<Props> = ({ children, disableCloseButton, fullWidth, isOpen, onClose }) => {
    const rootRef = useRef<HTMLDivElement>(null);

    return (
        <Dialog onClose={onClose} open={isOpen} ref={rootRef}>
            <div aria-hidden className="fixed inset-0 z-dropdown bg-black/30" />
            <div className="fixed inset-0 z-dropdown overflow-auto">
                {!disableCloseButton && (
                    <TextLink
                        className="absolute right-6 top-6 z-10 rounded-full bg-uxfViolet p-3 text-white transition is-hoverable:text-uxfOrange md:right-12 md:top-12"
                        onClick={onClose}
                        variant="custom"
                    >
                        <Icon name="cross" size={24} />
                    </TextLink>
                )}
                <Dialog.Panel className={`${!fullWidth ? "container" : ""} flex min-h-full items-center`}>
                    {children}
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};
