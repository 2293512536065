// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    address: { w: 27, h: 22 },
    arrowDiagonal: { w: 18, h: 18 },
    arrowRight: { w: 33, h: 33 },
    arrowRightCircle: { w: 20, h: 18 },
    arrowLeftCircle: { w: 20, h: 18 },
    bullhornSolid: { w: 512, h: 512 },
    clock: { w: 26, h: 26 },
    code: { w: 48, h: 40 },
    codeSolid: { w: 640, h: 512 },
    complex: { w: 60, h: 48 },
    cross: { w: 34, h: 34 },
    email: { w: 24, h: 18 },
    flame: { w: 43, h: 48 },
    goal: { w: 60, h: 48 },
    hat: { w: 48, h: 34 },
    heart: { w: 48, h: 42 },
    chevronRight: { w: 10, h: 18 },
    info: { w: 21, h: 20 },
    linkedin: { w: 18, h: 21 },
    location: { w: 20, h: 27 },
    medal: { w: 60, h: 48 },
    minus: { w: 24, h: 24 },
    penRulerSolid: { w: 512, h: 512 },
    phone: { w: 25, h: 25 },
    plus: { w: 24, h: 24 },
    proactivity: { w: 60, h: 48 },
    tagSolid: { w: 448, h: 512 },
    UXFan: { w: 40, h: 40 },
    vulcanSalute: { w: 60, h: 48 },
    check: { w: 30, h: 30 },
    checkInverted: { w: 30, h: 30 },
    checkSimple: { w: 23, h: 23 },
    infoInverted: { w: 18, h: 16 },
} as const;

export type IconsSet = keyof typeof ICONS;
