import { Layout } from "@shared/components/layout/layout";
import { getModalRef } from "@shared/services/modal-service";
import { CustomizedNextPage } from "@shared/types/app-types";
import { LocaleProvider } from "@translations/locale-context";
import { Modal } from "@ui/components/modal/modal";
import "@ui/utils/global-styles.css";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Nprogress from "nprogress";
import { useEffect } from "react";
import { SupportedLanguages } from "@config/translation-config";
import { useApollo } from "@shared/lib/Apollo";
import { ApolloProvider } from "@apollo/client";
import { FlashMessages } from "@ui/components/flash-messages/flash-messages";
import { getFlashMessagesRef } from "@shared/services/flash-messages-service";

type AppPropsWithLayout = AppProps & {
    Component: CustomizedNextPage;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
    const { events, locale } = useRouter();
    const apollo = useApollo(pageProps);
    // const cursorRef = useCursor();

    const currentLocale: SupportedLanguages = (locale as SupportedLanguages | undefined) ?? "cs";

    useEffect(() => {
        Nprogress.configure({ showSpinner: false });

        const onRouteChangeStart = () => Nprogress.start();
        const onRouteChangeStop = () => Nprogress.done();

        events.on("routeChangeStart", onRouteChangeStart);
        events.on("routeChangeComplete", onRouteChangeStop);
        events.on("routeChangeError", onRouteChangeStop);

        return () => {
            events.off("routeChangeStart", onRouteChangeStart);
            events.off("routeChangeComplete", onRouteChangeStop);
            events.off("routeChangeError", onRouteChangeStop);
        };
    }, [events]);

    if (Component.noLayout) {
        return (
            <LocaleProvider value={currentLocale}>
                <Component {...pageProps} />
            </LocaleProvider>
        );
    }

    return (
        <ApolloProvider client={apollo}>
            <LocaleProvider value={currentLocale}>
                <Layout>
                    <Component {...pageProps} />
                    <FlashMessages ref={getFlashMessagesRef()} />
                    <Modal ref={getModalRef()} />
                    {/*<div className={`custom-cursor custom-cursor--${currentLocale}`} ref={cursorRef} />*/}
                </Layout>
            </LocaleProvider>
        </ApolloProvider>
    );
}

export default appWithTranslation(App);
