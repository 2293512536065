import { UseAnchorProps, useAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import { cx } from "@uxf/core/utils/cx";
import { AnchorHTMLAttributes, forwardRef } from "react";

const VARIANTS = {
    custom: "transition",
    muted: "text-uxfMuted75 is-hoverable:text-uxfOrange transition",
    orange: "text-uxfOrange is-hoverable:text-uxfOrangeHover transition",
    violet: "text-uxfViolet is-hoverable:text-uxfOrange transition",
    white: "text-white is-hoverable:text-whiteHover transition",
    whiteMuted: "text-white/75 is-hoverable:text-white transition",
} as const;

export interface TextLinkProps extends UseAnchorProps, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> {
    underline?: boolean;
    variant?: keyof typeof VARIANTS;
}

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>((props, ref) => {
    const { children, className, underline = false, variant = "violet", ...restAnchorProps } = useAnchorProps(props);

    return (
        <a
            className={cx(
                VARIANTS[variant],
                underline ? "underline" : "no-underline",
                "is-hoverable:cursor-pointer",
                className,
            )}
            ref={ref}
            {...restAnchorProps}
        >
            {children}
        </a>
    );
});

TextLink.displayName = "TextLink";
