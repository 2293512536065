import { UseAnchorProps, useAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import { cx } from "@uxf/core/utils/cx";
import { AnchorHTMLAttributes, forwardRef } from "react";

const VARIANTS = {
    orange: "[--btn-color:theme(colors.uxfOrange)] [--btn-text:theme(colors.white)]",
    violet: "[--btn-color:theme(colors.uxfViolet)] [--btn-text:theme(colors.white)]",
    white: "[--btn-color:theme(colors.white)] [--btn-text:theme(colors.uxfViolet)]",
} as const;

export interface ButtonProps extends UseAnchorProps, Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> {
    outlined?: boolean;
    size?: "default" | "small";
    variant?: keyof typeof VARIANTS;
}

export const Button = forwardRef<HTMLAnchorElement, ButtonProps>(
    ({ children, className, outlined, variant = "violet", size = "default", ...restProps }, ref) => {
        const anchorProps = useAnchorProps<AnchorHTMLAttributes<HTMLAnchorElement>>({
            className: cx("_button bg-inherit is-hoverable:cursor-pointer", VARIANTS[variant], className),
            ...restProps,
        });

        return (
            <a ref={ref} {...anchorProps}>
                <span
                    className={cx(
                        "_button__inner",
                        outlined ? "border-2 border-current bg-[color:var(--btn-text)]" : "bg-current",
                        size === "default" ? "_button__inner--default" : "_button__inner--small",
                    )}
                >
                    <span className={!outlined ? "text-[color:var(--btn-text)]" : ""}>{children}</span>
                </span>
            </a>
        );
    },
);

Button.displayName = "Button";
