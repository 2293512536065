import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { FlashMessage, Notification } from "./flash-message";

export interface FlashMessagesRef {
    close: (notification: Notification) => void;
    closeAll: () => void;
    open: (notification: Notification) => void;
}

export const FlashMessages = forwardRef<FlashMessagesRef>((props, ref) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const open = useCallback(
        (notification: Notification) =>
            setNotifications(prev => [
                ...prev,
                {
                    ...notification,
                    variant: notification.variant || "default",
                    id: notification.id || Math.round(Math.random() * 1000),
                },
            ]),
        [],
    );

    const close = useCallback(
        (notification: Notification) => setNotifications(prev => prev.filter(n => n !== notification)),
        [],
    );

    const closeAll = useCallback(() => setNotifications([]), []);

    const innerRef = useRef<FlashMessagesRef>({
        close,
        closeAll,
        open,
    });

    useImperativeHandle(
        ref,
        () => ({
            close: innerRef.current.close,
            closeAll: innerRef.current.closeAll,
            open: innerRef.current.open,
        }),
        [],
    );

    const onClose = useCallback(
        (notification: Notification) => () => setNotifications(prev => prev.filter(n => n !== notification)),
        [],
    );

    return notifications.length > 0 ? (
        <div className="flex-end pointer-events-none fixed inset-0 z-50 flex p-6">
            <div
                className="w-max-sm flex w-full flex-col items-center space-y-4 sm:items-end sm:justify-end"
                role="list"
            >
                {notifications.map(notification => (
                    <FlashMessage
                        key={`${notification.id}`}
                        notification={notification}
                        onClose={onClose(notification)}
                    />
                ))}
            </div>
        </div>
    ) : null;
});

FlashMessages.displayName = "FlashMessages";
