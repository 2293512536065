import { ICONS, IconsSet } from "@config/icons";
import { ICON_SPRITE, ICONS_VERSION } from "@config/icons-config";
import { cx } from "@uxf/core/utils/cx";
import { CSSProperties, forwardRef, ForwardRefRenderFunction, memo, SVGAttributes } from "react";
import { rem } from "@uxf/styles/units/rem";

type OmittedProps = "height" | "name" | "preserveAspectRatio" | "viewBox" | "width";
type NameOrComponentType =
    | {
          name: IconsSet;
          component?: never;
      }
    | {
          name?: never;
          component: any;
      };

export type IconProps = Omit<SVGAttributes<SVGSVGElement>, OmittedProps> & {
    mode?: "meet" | "slice";
    size?: number;
} & NameOrComponentType;

const Component: ForwardRefRenderFunction<SVGSVGElement, IconProps> = (props, ref) => {
    const { className, mode = "meet", name, component, size, style = {}, ...restProps } = props;

    const sizes = name ? ICONS[name] : null;

    const CustomComponent = component;

    const _className = cx("_icon", className);
    const _preserveAspectRatio = `xMidYMid ${mode}`;

    return sizes ? (
        <svg
            className={_className}
            preserveAspectRatio={_preserveAspectRatio}
            ref={ref}
            role="img"
            style={
                {
                    "--i-h": size ? rem(size) : rem(sizes.h),
                    "--i-w": size ? rem(size) : rem(sizes.w),
                    ...style,
                } as Partial<CSSProperties>
            }
            viewBox={`0 0 ${sizes.w} ${sizes.h}`}
            {...restProps}
        >
            <use xlinkHref={`${ICON_SPRITE}?v=${ICONS_VERSION}#icon-sprite--${name}`} />
        </svg>
    ) : (
        <CustomComponent
            className={_className}
            preserveAspectRatio={_preserveAspectRatio}
            ref={ref}
            role="img"
            style={
                size
                    ? ({
                          "--i-h": rem(size),
                          "--i-w": rem(size),
                          ...style,
                      } as Partial<CSSProperties>)
                    : style
            }
            {...restProps}
        />
    );
};

export const Icon = memo(forwardRef(Component));

Icon.displayName = "Icon";
