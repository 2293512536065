import { useClickableProps } from "@uxf/core/hooks/useClickableProps";
import React, { FC, HTMLAttributes, MouseEventHandler, ReactNode, useEffect } from "react";

const VARIANTS = {
    error: "bg-red-600/90 is-hoverable:bg-red-900 text-white",
    success: "bg-green-500/90 is-hoverable:bg-green-700 text-white",
    default: "bg-white/90 is-hoverable:bg-gray-300 text-gray-800",
} as const;

export interface Notification {
    autoDismiss?: boolean;
    message: ReactNode;
    dismissTimeout?: number;
    id?: number;
    onClick?: () => void;
    variant?: keyof typeof VARIANTS;
}

export interface FlashMessageProps {
    notification: Notification;
    onClose: MouseEventHandler<HTMLDivElement>;
}

export const FlashMessage: FC<FlashMessageProps> = ({ notification, onClose }) => {
    useEffect(() => {
        const { autoDismiss = true, dismissTimeout = 5000 } = notification;

        let timer: number;
        if (autoDismiss) {
            timer = window.setTimeout(onClose, dismissTimeout);
        }
        return () => clearTimeout(timer);
    }, [onClose, notification]);

    const clickableProps = useClickableProps<HTMLAttributes<HTMLDivElement>>({
        className: `transitions pointer-events-auto rounded-2xl transition ${
            VARIANTS[notification.variant || "default"]
        } p-4 text-center font-bold shadow-flashMessage backdrop-blur is-hoverable:cursor-pointer`,
        onClick: onClose,
        role: "listitem alert",
    });

    return <div {...clickableProps}>{notification.message}</div>;
};
